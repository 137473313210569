define("ember-changeset/utils/assign", ["exports"], function (exports) {
  "use strict";

  exports["default"] = pureAssign;
  var merge = Ember.merge;

  var assign = Ember.assign || Object.assign || _assign;

  function _assign(origin) {
    for (var _len = arguments.length, sources = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      sources[_key - 1] = arguments[_key];
    }

    return sources.reduce(function (acc, source) {
      return merge(acc, source);
    }, merge({}, origin));
  }

  function pureAssign() {
    return assign.apply(undefined, [{}].concat(Array.prototype.slice.call(arguments)));
  }
});
define('ember-inputmask/components/date-input', ['exports', 'ember-inputmask/components/input-mask'], function (exports, _emberInputmaskComponentsInputMask) {
  'use strict';

  /**
   * `{{email-input}}` component.
   *
   * Displays an input that masks email addresses.
   */

  exports['default'] = _emberInputmaskComponentsInputMask['default'].extend({
    mask: 'date'
  });
});
define('ember-changeset/utils/is-promise', ['exports', 'ember-changeset/utils/is-object'], function (exports, _emberChangesetUtilsIsObject) {
  'use strict';

  exports['default'] = isPromise;

  var typeOf = Ember.typeOf;

  function isPromiseLike() {
    var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return typeOf(obj.then) === 'function' && typeOf(obj['catch']) === 'function' && typeOf(obj['finally']) === 'function';
  }

  function isPromise(obj) {
    return (0, _emberChangesetUtilsIsObject['default'])(obj) && isPromiseLike(obj);
  }
});
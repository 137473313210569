define('ember-changeset/utils/is-changeset', ['exports'], function (exports) {
  'use strict';

  exports['default'] = isChangeset;
  var get = Ember.get;

  var CHANGESET = '__CHANGESET__';

  exports.CHANGESET = CHANGESET;

  function isChangeset(changeset) {
    return get(changeset, '__changeset__') === CHANGESET;
  }
});
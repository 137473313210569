define('ember-validators/confirmation', ['exports', 'ember-validators/utils/validation-error'], function (exports, _emberValidatorsUtilsValidationError) {
  'use strict';

  exports['default'] = validateConfirmation;

  /**
   *  @class Confirmation
   *  @module Validators
   */

  /**
   * @method validate
   * @param {Any} value
   * @param {Object} options
   * @param {String} options.on The attribute to confirm against
   * @param {String} options.allowBlank If true, skips validation if the value is empty
   * @param {Object} model
   * @param {String} attribute
   */

  function validateConfirmation(value, options, model, attribute) {
    var on = Ember.get(options, 'on');
    var allowBlank = Ember.get(options, 'allowBlank');

    true && !Ember.isPresent(on) && Ember.assert('[validator:confirmation] [' + attribute + '] option \'on\' is required', Ember.isPresent(on));

    if (allowBlank && Ember.isEmpty(value)) {
      return true;
    }

    if (!Ember.isEqual(value, Ember.get(model, on))) {
      return (0, _emberValidatorsUtilsValidationError['default'])('confirmation', value, options);
    }

    return true;
  }
});
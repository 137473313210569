define('ember-place-autocomplete/components/place-autocomplete-field', ['exports', 'ember-place-autocomplete/templates/components/place-autocomplete-field'], function (exports, _emberPlaceAutocompleteTemplatesComponentsPlaceAutocompleteField) {
  'use strict';

  exports['default'] = Ember.Component.extend({
    /**
     * Set default values in component init
     */
    init: function init() {
      this._super.apply(this, arguments);
      this._applyDefaults();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      // TODO: check if a block was passed to avoid trying to set
      // the data attributes
      this._bindDataAttributesToInput();
      Ember.run.scheduleOnce('afterRender', this, 'setupComponent');
    },

    /**
     * Acts as an observer an updates the autocomplete instance with any
     * updated options that have been passed into the component.
     */
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('autocomplete')) {
        this.get('autocomplete').setOptions(this.getOptions());
      }
    },

    /**
     * Returns an object containing any options that are to be passed to the autocomplete instance.
     */
    getOptions: function getOptions() {
      var google = this.get('google') || (window ? window.google : null);
      var options = { types: this._typesToArray() };

      var latLngBnds = this.get('latLngBounds');

      if (latLngBnds && Object.keys(latLngBnds).length === 2) {
        // @see https://developers.google.com/maps/documentation/javascript/places-autocomplete#set_search_area
        var sw = latLngBnds.sw,
            ne = latLngBnds.ne;

        options.bounds = new google.maps.LatLngBounds(sw, ne);
      }

      var restrictions = this.get('restrictions');

      if (restrictions && Object.keys(restrictions).length > 0) {
        options.componentRestrictions = restrictions;
      }

      return options;
    },

    // Wait until the google library is loaded by calling this method
    // every 100ms
    setupComponent: function setupComponent() {
      var _this = this;

      if (document && window && window.google && window.google.maps) {
        this.setAutocomplete();
        if (this.get('withGeoLocate')) {
          this.geolocateAndSetBounds();
        }
        this.get('autocomplete').addListener('place_changed', function () {
          Ember.run(function () {
            _this.placeChanged();
          });
        });
      } else {
        if (!this.isDestroyed && !this.isDestroying) {
          Ember.run.later(this, 'setupComponent', 100);
        }
      }
    },
    keyDown: function keyDown(e) {
      if (this.get('preventSubmit') && Ember.isEqual(e.keyCode, 13)) {
        e.preventDefault();
      }
    },
    willDestroy: function willDestroy() {
      if (Ember.isPresent(this.get('autocomplete'))) {
        var google = this.get('google') || (window ? window.google : null);
        if (google && google.maps && google.maps.event) {
          google.maps.event.clearInstanceListeners(this.get('autocomplete'));
        }
      }
    },
    setAutocomplete: function setAutocomplete() {
      if (Ember.isEmpty(this.get('autocomplete'))) {
        var inputElement = document.getElementById(this.elementId).getElementsByTagName('input')[0],
            google = this.get('google') || window.google; //TODO: check how to use the inyected google object

        var autocomplete = new google.maps.places.Autocomplete(inputElement, this.getOptions());
        this.set('autocomplete', autocomplete);
      }
    },

    // @see https://developers.google.com/maps/documentation/javascript/places-autocomplete#set_search_area
    geolocateAndSetBounds: function geolocateAndSetBounds() {
      var _this2 = this;

      var navigator = this.get('navigator') || (window ? window.navigator : null);
      var autocomplete = this.get('autocomplete');
      if (navigator && navigator.geolocation && Ember.isPresent(autocomplete)) {
        navigator.geolocation.getCurrentPosition(function (position) {
          var google = _this2.get('google') || window.google;
          var geolocation = { lat: position.coords.latitude, lng: position.coords.longitude };
          var circle = new google.maps.Circle({ center: geolocation, radius: position.coords.accuracy });
          autocomplete.setBounds(circle.getBounds());
        });
      }
    },
    placeChanged: function placeChanged() {
      var place = this.get('autocomplete').getPlace();
      this._callCallback('placeChangedCallback', place);

      // If setValueWithProperty is undefined, use Google Autocomplete default behavior
      if (place[this.get('setValueWithProperty')] !== undefined) {
        this.set('value', place[this.get('setValueWithProperty')]);
      }
    },
    _callCallback: function _callCallback(callback, place) {
      var callbackFn = this.get(callback);
      if (Ember.isEqual(Ember.typeOf(callbackFn), 'function')) {
        callbackFn(place);
      } else {
        var actionName = this.get(callback);
        if (Ember.isPresent(this.get('handlerController')) && Ember.isPresent(actionName)) {
          this.get('handlerController').send(actionName, place);
        }
      }
    },
    _typesToArray: function _typesToArray() {
      var types = this.get('types');

      if (Ember.isArray(types)) {
        return types;
      } else if (Ember.typeOf(types) === 'string') {
        if (types.trim() === '') {
          return [];
        } else {
          return types.split(',');
        }
      } else {
        return [];
      }
    },
    _applyDefaults: function _applyDefaults() {
      var defaultProperties = {
        layout: _emberPlaceAutocompleteTemplatesComponentsPlaceAutocompleteField['default'],
        disabled: false,
        inputClass: 'place-autocomplete--input',
        types: undefined,
        restrictions: {},
        tabindex: 0,
        withGeoLocate: false,
        setValueWithProperty: undefined,
        preventSubmit: false
      };

      for (var property in defaultProperties) {
        if (Ember.isBlank(this.get(property))) {
          this.set(property, defaultProperties[property]);
        }
      }
    },
    _bindDataAttributesToInput: function _bindDataAttributesToInput() {
      var _this3 = this;

      var properties = Object.keys(this).filter(function (prop) {
        return prop.indexOf('data-') >= 0;
      }) || [];
      var input = document.getElementById(this.elementId).getElementsByTagName('input')[0];
      properties.forEach(function (property) {
        return input.setAttribute(property, _this3.get(property));
      });
    },

    actions: {
      focusOut: function focusOut() {
        this._callCallback('focusOutCallback');
      }
    }
  });
});
define('ember-inputmask/components/phone-number-input', ['exports', 'ember', 'ember-inputmask/components/input-mask'], function (exports, _ember, _emberInputmaskComponentsInputMask) {
  'use strict';

  /**
   * `{{phone-number-input}}` component.
   *
   * Displays an input that masks a US phone number. Country code
   * not included because this is specifically US formatting.
   *
   * Future: Add config options that allow users to set locality
   * app wide.
   *
   * OPTIONS:
   *   extensions - bool
   *     Allows optional extensions to be added to numbers
   */

  exports['default'] = _emberInputmaskComponentsInputMask['default'].extend({
    mask: '(999) 999-9999',

    updateMask: function updateMask() {
      if (this.get('extensions')) {
        this.set('mask', '(999) 999-9999[ x 9{1,4}]');
      }

      this._super();
    },

    _maskShouldChange: _ember['default'].observer('mask', 'extensions', function () {
      _ember['default'].run.once(this, 'updateMask');
    })
  });
});
define('ember-validators/collection', ['exports', 'ember-validators/utils/validation-error'], function (exports, _emberValidatorsUtilsValidationError) {
  'use strict';

  exports['default'] = validateCollection;

  /**
   *  @class Collection
   *  @module Validators
   */

  /**
    * @method validate
    * @param {Any} value
    * @param {Object} options
    * @param {Boolean} options.collection
    * @param {Object} model
    * @param {String} attribute
    */

  function validateCollection(value, options, model, attribute) {
    var collection = Ember.get(options, 'collection');

    true && !Ember.isPresent(collection) && Ember.assert('[validator:collection] [' + attribute + '] option \'collection\' is required', Ember.isPresent(collection));

    if (collection === true && !Ember.isArray(value)) {
      return (0, _emberValidatorsUtilsValidationError['default'])('collection', value, options);
    }

    if (collection === false && Ember.isArray(value)) {
      return (0, _emberValidatorsUtilsValidationError['default'])('singular', value, options);
    }

    return true;
  }
});
define('ember-validators/index', ['exports', 'ember-require-module'], function (exports, _emberRequireModule) {
  'use strict';

  exports.validate = validate;

  function validate(type) {
    var validator = (0, _emberRequireModule['default'])('ember-validators/' + type);

    true && !Ember.isPresent(validator) && Ember.assert('Validator not found of type: ' + type + '.', Ember.isPresent(validator));

    for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    return validator.apply(undefined, args);
  }
});
define('ember-changeset-validations/validators/number', ['exports', 'ember-changeset-validations/utils/validation-errors', 'ember-changeset-validations/utils/with-defaults', 'ember-validators'], function (exports, _emberChangesetValidationsUtilsValidationErrors, _emberChangesetValidationsUtilsWithDefaults, _emberValidators) {
  'use strict';

  exports['default'] = validateNumber;

  function validateNumber() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    options = (0, _emberChangesetValidationsUtilsWithDefaults['default'])(options, { allowString: true, allowNone: false });

    if (options.allowBlank) {
      options.allowNone = true;
    }

    return function (key, value) {
      var result = (0, _emberValidators.validate)('number', value, options, null, key);
      return result === true ? true : (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, result);
    };
  }
});
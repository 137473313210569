define('ember-place-autocomplete/initializers/register-google', ['exports'], function (exports) {
  'use strict';

  exports.initialize = initialize;

  function initialize() {
    var application = arguments[1] || arguments[0];
    if (window && window.google) {
      application.register('google:main', window.google, { instantiate: false });
      application.inject('component', 'google', 'google:main');
    }
  }

  exports['default'] = {
    name: 'register-google',
    initialize: initialize
  };
});
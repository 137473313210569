define('ember-model-validator/mixins/object-validator', ['exports', 'ember-data', 'ember-model-validator/mixins/model-validator'], function (exports, _emberData, _emberModelValidatorMixinsModelValidator) {
  'use strict';

  exports['default'] = Ember.Mixin.create(_emberModelValidatorMixinsModelValidator['default'], {
    errors: _emberData['default'].Errors.create(),

    clearErrors: function clearErrors() {
      this.set('errors', _emberData['default'].Errors.create());
    },
    pushErrors: function pushErrors(errors) {
      for (var attribute in errors) {
        var messages = errors[attribute];
        this.get('errors').add(attribute, messages);
      }
    },
    _modelRelations: function _modelRelations() {}
  });
});